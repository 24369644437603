import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import * as Api from 'src/api'
import { BalanceLargePositiveIcon } from 'src/assets/icons/customIcons/BalanceLargePositive'
import { CreditsLargeIcon } from 'src/assets/icons/customIcons/CreditsLarge'
import { LectourLargeIcon } from 'src/assets/icons/customIcons/LectourLarge'
import { LibraryLargeIcon } from 'src/assets/icons/customIcons/LibraryLarge'
import { NewsIcon } from 'src/assets/icons/customIcons/News'
import { RedArrowIcon } from 'src/assets/icons/customIcons/RedArrow'
import { ScheduleLargeIcon } from 'src/assets/icons/customIcons/ScheduleLargeIcon'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import NoPhoto from 'src/imgs/no-photo.jpg'
import InfoCard from 'src/tailwind/components/InfoCard'
import { LecturerCard } from 'src/tailwind/components/LecturerCard'
import { News } from 'src/tailwind/components/News'
import { ScheduleList } from 'src/tailwind/components/ScheduleList'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentHomePage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('system:home_page')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()

  return (
    <div>
      <Section homePage>
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <Information />
          </ErrorBoundary>
        </React.Suspense>
      </Section>
      {userState.data?.regime.functionalIsLimited === false && (
        <Section
          homePage
          title={t('news:news')}
          icon={<NewsIcon />}
          rightElement={
            <Link
              to={`/${locale}/news`}
              className="group relative flex cursor-pointer items-center rounded-card py-[7px] pl-4 pr-7 hover:bg-hover-card"
            >
              <span className="mr-[7px] text-primaryTextColor">{t('news:all_news')}</span>
              <div className="absolute right-[13px] duration-500 ease-in-out group-hover:mr-[-5px]">
                <RedArrowIcon />
              </div>
            </Link>
          }
          data-testid="news"
        >
          <React.Suspense fallback={<Loader className="m-auto flex" />}>
            <ErrorBoundary errorElement={<ErrorElement />}>
              <NewsOld />
            </ErrorBoundary>
          </React.Suspense>
        </Section>
      )}
      <Section homePage title={t('schedule:today_schedule')} icon={<ScheduleLargeIcon />}>
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <Schedule />
          </ErrorBoundary>
        </React.Suspense>
      </Section>
      <Section homePage title={t('lecturer:lecturer')} icon={<LectourLargeIcon />}>
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <Lecturers />
          </ErrorBoundary>
        </React.Suspense>
      </Section>
    </div>
  )
}

function NewsOld(): React.ReactElement {
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const fetching = useApi({
    endpoint: Api.getNews,
    params: React.useMemo(() => ({ headers, query: { perPage: 2, page: 1 } }), [headers]),
  })
  const data = fetching.data.data

  return (
    <ul className="mb-0 flex gap-3 pl-0 xxs:flex-col xs:flex-col">
      {data.map((n) => {
        return (
          <News
            to={`/${locale}/news/${n.id}`}
            key={n.id}
            title={n.title}
            date={format(parseISO(n.createdAt), 'd MMMM yyyy, HH:mm', {
              locale: dateLocale,
            })}
            className="w-full"
            data-testid={`news/${n.id}`}
          />
        )
      })}
    </ul>
  )
}

function Schedule(): React.ReactElement | null {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentSchedulesCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const schedule = data

  if (userState.data == null) return null

  const today = new Date().getDay()
  const todaySchedule = schedule.filter((schedule) => schedule.number === today)

  return (
    <div className="overflow-x-scroll">
      {todaySchedule != null && todaySchedule.length > 0 ? (
        <ul className="inline-block w-full min-w-[768px] list-none pl-0">
          {todaySchedule[0]?.schedules.map((schedule) => (
            <ScheduleList
              key={schedule.id}
              to={`/${locale}/student/courses/${schedule.course.id}/groups`}
              time={schedule.hour.times}
              classroom={schedule.locationName ?? ''}
              lecture={schedule.group.lecturers}
              group={schedule.group.name}
              // finished
              online={schedule.lectureTypeName}
              course={schedule.course.fullName}
              data-testid={`schedule/${schedule.id}`}
            />
          ))}
        </ul>
      ) : (
        <div
          className="mr-2 border-0 border-lightSecondaryWarning text-center text-primaryTextColor"
          style={{ borderRadius: '8px' }}
          role="alert"
          data-testid="schedule/empty"
        >
          {t('schedule:today_your_schedule_is_empty')}
        </div>
      )}
    </div>
  )
}
const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  arrows: true,
  rows: 1,
  responsive: [
    {
      breakpoint: 1921,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
}

const sliderSettingsWithArrowsDisabled = {
  ...sliderSettings,
  arrows: false,
}

function Lecturers(): React.ReactElement {
  const t = useTranslatable()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentLecturersCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const lecturers = data

  if (lecturers.length === 0) {
    return (
      <div
        className="mr-2 border-0 border-lightSecondaryWarning text-center text-primaryTextColor"
        style={{ borderRadius: '8px' }}
        role="alert"
        data-testid="lecturers/empty"
      >
        {t('error:could_not_find_lecturers_for_selected_courses')}
      </div>
    )
  }

  return (
    <Slider {...sliderSettings}>
      {lecturers.map((lecturer) => {
        return (
          <LecturerCard
            data-testid={`lecturer/${lecturer.id}`}
            to={`/${locale}/users/${lecturer.uid}`}
            key={lecturer.id}
            lecturer={lecturer.fullName}
            course={lecturer.group?.course?.name ?? ''}
            photo={
              <span style={{ fontSize: '1.25rem' }}>
                <img
                  src={lecturer.photoUrl != null && lecturer.photoUrl.length > 0 ? lecturer.photoUrl : NoPhoto}
                  className="rounded-full"
                />
              </span>
            }
            group={lecturer.group?.name ?? ''}
          />
        )
      })}
    </Slider>
  )
}

function Information(): JSX.Element {
  const t = useTranslatable()
  const userState = useUserState()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentRating,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const rating = data

  return (
    <Slider {...sliderSettingsWithArrowsDisabled}>
      {userState.data?.regime.functionalIsLimited === false && (
        <InfoCard
          title={t('library:quantity')}
          to={`/${locale}/library`}
          icon={<LibraryLargeIcon />}
          quantity={userState.data != null ? userState.data.libraryBalance : ''}
        />
      )}
      {userState.data?.regime.functionalIsLimited === false && (
        <InfoCard
          title={t('billing:financial_information')}
          to={`/${locale}/student/billing/balance`}
          icon={<BalanceLargePositiveIcon />}
          quantity={userState.data?.billingBalance != null ? (userState.data.billingBalance / 100).toFixed(2) : ''}
        />
      )}
      <InfoCard
        title={t('person:rating')}
        to={`/${locale}/student/rating`}
        icon={<CreditsLargeIcon />}
        quantity={rating.averagePoint?.toFixed(2) ?? 0}
        text={t('person:current_rating')}
      />
    </Slider>
  )
}
